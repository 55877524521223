import React from "react";
import "./Join.css";
const Join = () => {
  return (
    <div className="join">
      <div>Join Us Page Coming Soon</div>
    </div>
  );
};

export default Join;
