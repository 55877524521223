import React from "react";
import "./Team.css"; // Import the CSS file for styling
import Employeecard001 from "../../Assets/Employeecard/Suresh.png";
import Employeecard002 from "../../Assets/Employeecard/venkat.png";
import Employeecard003 from "../../Assets/Employeecard/Joseph.png";
import Employeecard004 from "../../Assets/Employeecard/Neelam.png";
import Employeecard005 from "../../Assets/Employeecard/karthikms.png";
import Employeecard006 from "../../Assets/Employeecard/aditya.png";
import Employeecard007 from "../../Assets/Employeecard/shekar.png";
import Employeecard008 from "../../Assets/Employeecard/ram.png";
import Employeecard009 from "../../Assets/Employeecard/racheal.png";
import Employeecard010 from "../../Assets/Employeecard/Ashothosh.png";
import Employeecard0011 from "../../Assets/Employeecard/Nayana.png";
import Employeecard0012 from "../../Assets/Employeecard/murali.png";
import Employeecard0013 from "../../Assets/Employeecard/rajeev.png";
import Employeecard0014 from "../../Assets/Employeecard/roshan.png";
import Employeecard0015 from "../../Assets/Employeecard/Shiva.png";
import Employeecard0016 from "../../Assets/Employeecard/swanand.png";
import Employeecard0017 from "../../Assets/Employeecard/chaitra.png";
import Employeecard0018 from "../../Assets/Employeecard/varsha.png";
import Employeecard0019 from "../../Assets/Employeecard/prashana.png";
import Employeecard0020 from "../../Assets/Employeecard/Bilal.png";
import Employeecard0021 from "../../Assets/Employeecard/ruhi.png";
import Employeecard0022 from "../../Assets/Employeecard/Pooja.png";
import Employeecard0023 from "../../Assets/Employeecard/prateek.png";
import Employeecard0024 from "../../Assets/Employeecard/pournima.png";
import Employeecard0025 from "../../Assets/Employeecard/Aashish.png";
import Employeecard0026 from "../../Assets/Employeecard/manoj.png";


import ourTeam from "../../Assets/OurTeam.png";
import ourTeamTwo from "../../Assets/OurTeamTwo.png";
const Team = () => {

  return (
    <>
      <div className="image-container">
        <img src={ourTeam} alt="pin" className="banner" />
        <div className="centered-text">OUR TEAM</div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10"><img src={ourTeamTwo} alt="pin" className="" /></div>
          <div className="col-1"></div>
        </div>
      </div>


      <div className="container team-layout">
        <div className="row">


          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard002} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Venkatesh Hariharan</h3>
                <h6>CEO</h6>
                <p className="mt-5">Mastering the art of CEO coolness with calm confidence - like a CEO who’s always two steps ahead in a Chess game </p>
              </div>
            </div>
          </div>


          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard001} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Suresh Mallandira</h3>
                <h6>India Operation Leader</h6>
                <p className="mt-5">Managing operations with a vibrant spirit and enthu that makes coffee seem like a calming beverage</p>
              </div>
            </div>
          </div>

          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard003} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Joseph Vathalloor</h3>
                <h6>RPA Program Manager</h6>
                <p className="mt-5">RPA’s jaded Guru—handling robots with the patience of a saint and a touch of ‘I’ve survived worse </p>
              </div>
            </div>
          </div>

          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard0011} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Nayana Baligar</h3>
                <h6>HR Assistant</h6>
                <p className="mt-5">HR Ninja: slicing through tasks with lightning speed </p>
              </div>
            </div>
          </div>




        </div>


        <div className="row mt-5">



          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard005} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Karthik Mahadeva S</h3>
                <h6>Software Developer</h6>
                <p className="mt-5">Bringing playful ingenuity to every line of code</p>
              </div>
            </div>
          </div>


          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard004} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Neelam Moolchandani</h3>
                <h6>Senior Scrum Master</h6>
                <p className="mt-5">Championing team success with unwavering principles and a touch of grace</p>
              </div>
            </div>
          </div>

          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard0019} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Prasanna Kumar</h3>
                <h6>Data Engineer</h6>
                <p className="mt-5">Hiking through data forests and crossing analytic deserts, one dataset at a time</p>
              </div>
            </div>
          </div>

          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard0020} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Bilal Naik</h3>
                <h6>Web Developer</h6>
                <p className="mt-5">Building web experiences that are as uplifting as they are engaging </p>
              </div>
            </div>
          </div>



        </div>


        <div className="row mt-5">



          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard0024} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Pournima Shengali</h3>
                <h6>Senior Salesforce Administrator</h6>
                <p className="mt-5">Giving Salesforce a high-five with pioneering energy and a lot of pizzazz</p>
              </div>
            </div>
          </div>


          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard0021} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Ruhi Sayyad</h3>
                <h6>Senior RPA Developer</h6>
                <p className="mt-5">Fixing RPA hiccups quicker than you can say 'Robotic Process Automation! </p>
              </div>
            </div>
          </div>

          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard0022} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Pooja Bandekar</h3>
                <h6>Talent Acquisition Specialist</h6>
                <p className="mt-5">On a talent treasure hunt, armed with a pioneering spirit and a resume-finding GPS </p>
              </div>
            </div>
          </div>


          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard0023} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Prateek Pandey</h3>
                <h6>Senior Bhoomi Developer</h6>
                <p className="mt-5">Crafting solutions with a contemplative approach and a commitment to excellence</p>
              </div>
            </div>
          </div>

        </div>


        <div className="row mt-5">

          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard006} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Adithya Vigneshvar</h3>
                <h6>Scrum Master</h6>
                <p className="mt-5">Making agile processes feel effortless and welcoming</p>
              </div>
            </div>
          </div>

          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard009} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Rachel Joseph</h3>
                <h6>Business Analyst</h6>
                <p className="mt-5">Brightening up business analysis with a dynamic and positive outlook </p>
              </div>
            </div>
          </div>

          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard007} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Shekhar Jampula</h3>
                <h6>Mulesoft Architect</h6>
                <p className="mt-5">Integrating systems with such calm, you'd think he’s on a permanent vacation </p>
              </div>
            </div>
          </div>


          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard0026} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Manoj Kumar Gona</h3>
                <h6>Senior Scrum Master</h6>
                <p className="mt-5">Running Scrum like a chill playlist—smooth, mellow, and never missing a beat</p>
              </div>
            </div>
          </div>

        </div>


        <div className="row mt-5">

          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard010} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Ashutosh Sinha</h3>
                <h6>Senior Software Developer</h6>
                <p className="mt-5">Leading development with decisive action and expert precision</p>
              </div>
            </div>
          </div>

          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard0012} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Muralimohan Jonnalagada</h3>
                <h6>Web Architect </h6>
                <p className="mt-5">Crafting web wonders while keeping the '404 Not Found' errors at bay</p>
              </div>
            </div>
          </div>

          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard0013} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Rajeev Ranjan</h3>
                <h6>Senior Data Engineer</h6>
                <p className="mt-5">Handling data engineering with calm precision and a relaxed attitude </p>
              </div>
            </div>
          </div>


          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard0014} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Roshan Cheeyapana</h3>
                <h6>DevOps Engineer</h6>
                <p className="mt-5">Converting DevOps chaos into smooth sailing, one clever fix at a time</p>
              </div>
            </div>
          </div>

        </div>

        <div className="row mt-5">

          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard0015} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Shivashankar Manthena</h3>
                <h6>Senior Salesforce Developer</h6>
                <p className="mt-5">Turning Salesforce headaches into ‘aha!’ moments with the strategic brilliance of a game show contestant</p>
              </div>
            </div>
          </div>

          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard0016} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Swanand Thakur</h3>
                <h6>Senior Business Analyst</h6>
                <p className="mt-5">The Detail-Detective who makes data Drama-free</p>
              </div>
            </div>
          </div>

          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard0017} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Chaithra Kushalappa</h3>
                <h6>Senior Developer</h6>
                <p className="mt-5">Her: Where 'It works on my machine' is a starting point, not an end goal</p>
              </div>
            </div>
          </div>


          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard0018} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Varshaa Punitharaj</h3>
                <h6>Environmental Business Analyst</h6>
                <p className="mt-5">Turning eco-data into green solutions—because saving the planet shouldn't be boring!</p>
              </div>
            </div>
          </div>

        </div>

        <div className="row mt-5">

          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard008} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Ramnath Balasubramanian</h3>
                <h6>Business Analyst</h6>
                <p className="mt-5">Injecting youthful enthusiasm into every data-driven decision</p>
              </div>
            </div>
          </div>


          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Employeecard0025} alt="Avatar" style={{ width: "300px", height: "300px", backgroundColor: "black" }} />
              </div>
              <div className="flip-card-back">
                <h3>Aashish Kumar</h3>
                <h6>UX Designer</h6>
                <p className="mt-5">Crafting designs so inviting, even your computer will want to hang out</p>
              </div>
            </div>
          </div>






        </div>


      </div>

    </>
  );
};

export default Team;
