import React from "react";
import Aboutus from "../../Assets/about.png";
import "./About.css";
import vrilogo from "../../Assets/Icon.png";
import aboutusbg from '../../Assets/WhyChooseBG.png'
import divider from '../../Assets/Rectangle 80.png'

const About = () => {
  return (
    <div>
      <img src={Aboutus} alt="about" className="bgimg"/>
      <div className="about-container">
        <div class="row alignrow">
          <div class="col-auto">
          <img src={divider}/>
          </div>
          <div class="col-5">
            <p className="subt2">who are we ?</p>
            </div>
            <div class="col-6">
            <p className="subt3">We drive sustainable digital solutions with a focus on innovation. As a leading technology company, we specialize in advancements across Waste, Water, Electric, and Oil & Gas utilities sector.</p>
            </div>
            </div>
            
            <div class="row alignrow">
            <div class="col-auto">
            <img src={divider}/>
            </div>
            <div class="col-5">
              <p className="subt2">our mission</p>
              </div>
              <div class="col-6">
              <p className="subt3">Our mission is to deliver unparalleled value through rare and innovative solutions that cannot be easily replicated. We are committed to being meticulously organized, ensuring that our unique offerings empower our clients to achieve exceptional results and sustain a competitive advantage in their markets</p>
              </div>

              </div>
      </div>

    
      <div className="whychooseus">
 
      <h2 className="about-secondtitle">Why choose</h2>
      <h2 className="t2">VRIO DIGITAL</h2>
      <div class="container1">
        <div class="row">
          <div class="col">
            <img
              src={vrilogo}
              alt="Description of image"
              className="responsive-img"
            />
            <span className="highlight">end to end capability</span>
          </div>
          <div class="col">
            <img
              src={vrilogo}
              alt="Description of image"
              className="responsive-img"
            />
            <span className="highlight">innovative technology</span>
          </div>
          <div class="w-100"></div>
          <div class="col">
            <img
              src={vrilogo}
              alt="Description of image"
              className="responsive-img"
            />
            <span className="highlight"> expertise and experience</span>
          </div>
          <div class="col">
            <img
              src={vrilogo}
              alt="Description of image"
              className="responsive-img"
            />
            <span className="highlight">client-centric approach</span>
          </div>
        </div>
      </div>
      <center>
        <div className="t3placement row">
          <div class="col-auto">
          <p className="t3">Our Team</p>
          </div>
          <div class="col-auto">
          <p className="t3">Careers</p>     
          </div>
      </div>
      </center>
      </div>
   
    </div>
  );
};

export default About;
