// import React from "react";
// import "./Industries.css";
// const Industries = () => {
//   return (
//     <div className="industries">
//       <div>Industries Page Comming Soon</div>
//     </div>
//   );
// };

// export default Industries;

import React from "react";
import "./Industries.css";

const SquareBox = () => {
  return <div class="striped-box"></div>;
};

export default SquareBox;
