import React, { useState, useEffect } from "react";
import ElectricUtilities from "../../Assets/sliderimg/ElectricUtilities_v04.png";
import EnergyManagement from "../../Assets/sliderimg/EnergyManagement.png";
import OilGas from "../../Assets/sliderimg/Oil&Gas.png";
// import Sustainibility from "../../Assets/sliderimg/Sustainibility.png";
import WaterandWasteWaterUtilities from "../../Assets/sliderimg/WaterandWasteWaterUtilities.png";
import WaterUtilities from "../../Assets/sliderimg/WaterUtilities.png";
import HomeContent from "../../ContentDisplay/HomePage/Homecontent";
import "./Home.css";
const sliderImages = [
  {
    src: ElectricUtilities,
    defaultText: "ENERGY MANAGEMENT",
    overlayText:
      "The energy management sector focuses on the production distribution, and efficient use of energy",
  },
  {
    src: EnergyManagement,
    defaultText: "ELECTRIC &  GAS UTILITIES",
    overlayText:
      "Electric utilities are organizations that generate, transmit, and distribute electricity to consumers, including residential, commercial, and industrial customers.",
  },
  {
    src: OilGas,
    defaultText: "OIL & GAS",
    overlayText:
      "These utilities play a vital role in providing the energy necessary for various sectors, including residential, commercial, industrial, and transportation.",
  },
  {
    src: WaterandWasteWaterUtilities,
    defaultText: "HAZARDOUS  WASTE MANAGEMENT",
    overlayText:
      "Proper management and treatment of wastewater are essential to protect public health, the environment, and water resources.",
  },
  {
    src: WaterUtilities,
    defaultText: "WATER UTILITIES",
    overlayText:
      "Water utilities are essential for delivering safe drinking water, managing wastewater, and ensuring the sustainable use of water resources.",
  },
];
const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? sliderImages.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === sliderImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div>
      <HomeContent />
      <div className="home-container">
        <div className="text-container">
          <h1>Industries</h1>
          <h2>we work in</h2>
        </div>
        <div className="slider-container">
          <button className="slider-control slider-prev" onClick={handlePrev}>
            ❮
          </button>
          <div className="slider-wrapper">
            <div
              className="slider"
              style={{
                transform: `translateX(-${(currentIndex * 100) / 4}%)`,
                transition: "transform 0.5s ease",
              }}
            >
              {[...sliderImages, ...sliderImages].map((item, index) => (
                <div key={index}>
                  <div className="zoom-wrapper">
                    <img src={item.src} alt={`Slider Image ${index + 1}`} />
                    <div className="default-text">{item.defaultText}</div>
                    <div className="overlay">
                      <p>{item.overlayText}</p>
                      <button className="read-more-button">Read More</button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button className="slider-control slider-next" onClick={handleNext}>
            ❯
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
