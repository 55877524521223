import React from "react";
import "./Solution.css";
const Solution = () => {
  return (
    <div className="solution">
      <div>Solution Page Coming Soon</div>
    </div>
  );
};

export default Solution;
