import React from "react";
import map from "../Assets/map.png";
import message from "../Assets/message.png";
import linkedin from "../Assets/linkedin.png";
import "./Footer.css"; // Import custom CSS if needed

const Footer = () => {
  return (
    <footer className="footer py-4">
      <div className="container">
        <div className="row">
          {/* <div className="col-4 d-flex mb-2">
            <div className="col-1">
              <img src={message} alt="message" />
            </div>
            <div className="col-11">
              <span>contact@vriodigital.com</span>
            </div><br />
            <div className="d-flex">

            </div>
            <div className="col-1">
              <img src={linkedin} alt="linkedin" />
            </div>
            <div className="col-11">
              <span>linkedin.com/vrio-digital</span>
            </div>

          </div> */}

          <div className="col-4 mb-2">
            <span className="message"><img src={message} alt="message" className="m-2" />contact@vriodigital.com</span><br />
            <span className="linked"><img src={linkedin} alt="linked" className="m-2" /><a href="https://www.linkedin.com/company/vrio-digital">VRIO Digital</a></span>
          </div>
          <div className="col-4 mb-2">
          </div>
          <div className="col-4 d-flex mb-2">
            <div className="col-1">
              <img src={map} alt="pin" className="map m-1" />
            </div>
            <div className="col-11">
              <span>BLOCK-G, GOPALAN GLOBAL AXIS, Rd Number 9, opp. Satya Sai Hospital, KIADB Industrial Area, Whitefield, Bengaluru, Karnataka 560066</span>
            </div>


          </div>
        </div>


      </div>
    </footer>
  );
};

export default Footer;
